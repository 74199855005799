import React from 'react';
import './App.scss';
import NavBar from './components/navBar';
import Home from './components/home';
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contact';
import MyResume from './components/myResume';
import Animation from './components/animation';
import Footer from './components/footer';

function App() {
  return (
    <div>
        <Animation />
      <div id="nav-bar">
        <NavBar />
      </div>
      
      <div>
        {/* <Home /> */}
      </div>
      <div>
        <About />
      </div>
      {/* <div>
        <Projects />
      </div> */}
      <div>
        <Contact />
      </div>
      <div>
        <Footer />
      </div>      
    </div>
  );
}

export default App;
