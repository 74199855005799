import React from 'react';

const Contact = () => {
  return ( 
    <div id="contact">
      <div className="cont">
        <h1>Contact</h1>
        <div id="email">
          <a href="mailto: witman2@yahoo.com"><i className="fa fa-envelope text-info"></i></a>
        </div>
      </div>
    </div>
   );
}
 
export default Contact;