import React from 'react';
import Card from 'react-bootstrap/Card'
import me from '../images/WS.jpg'

const About = () => {
  return ( 
    <section id="about">
      <Card>
      <div id="container-card" className="container">
        <h1>About</h1>
        <div className="row">
          <div className="col-md-2">
            
          </div>
          <div id="me-container" className="col-md-8">
            <i><img className="me" alt="imageOfMe" src={me} /></i><br />
            <p>My name is Withman Simprevil, I believe I can call myself 
              a lot of things because of the system 
              of my versatility. I am a <span>Software Developer </span>
              who is passionate about technology with a great attitude that can deal efficiently and
              effectively in a project. Keen on accuracy with initiative and go-getter mindset. A fast
              learner, problem solver, communicator, collaborator, and motivator. Demonstrated ability
              to work effectively within a team environment that builds company satisfaction and self-
              confidence.</p>
          </div>
          <div className="col-md-2">
            
          </div>
          
        </div>
        <div className="row">
          <div className="col-md-6 skills-bar mb-1">
            <Card id="skills-card">
              <Card.Header><h4 className="text-center text-info">Skills</h4></Card.Header>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p>JavaScript</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '75%'}}>75%</div>
                    </div>
                    <p>React.JS</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '85%'}}>85%</div>
                    </div>
                    <p>BootStrap</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '75%'}}>75%</div>
                    </div>
                    <p>HTML5</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '80%'}}>80%</div>
                    </div>
                    <p>SASS/CSS</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '90%'}}>90%</div>
                    </div>
                    <p>RES APIs</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '70%'}}>70%</div>
                    </div>
                
                  </div>
                  <div className="col-md-6">
                    <p>Node.JS</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '70%'}}>70%</div>
                    </div>
                    <p>Python</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '65%'}}>65%</div>
                    </div>
                    <p>Java</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '60%'}}>60%</div>
                    </div>
                    <p>C#</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '40%'}}>40%</div>
                    </div>
                    <p>AWS</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '50%'}}>50%</div>
                    </div>
                    <p>MongoDB</p>
                    <div className="progress">
                      <div className="progress-bar bg-info" style={{width: '75%'}}>75%</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <div className="row">
               <div className="col-md-12">
               <Card id="extra-card">
                <Card.Header><h4 className="text-center">Extra</h4></Card.Header>
                <div className="container">
                <div className="container-1">
                  <div >
                    <li>React Native</li>
                    <li>React Hooks</li>
                    <li>Express.JS</li>
                    <li>Serverless</li>
                    <li>Postman</li>
                    <li>Git</li>
                  </div>
                  <div>
                    <li>Visual Studio</li>
                    <li>IntelliJ</li>
                    <li>Source Tree</li>
                    <li>Photoshop</li>
                    <li>Illustrator</li>
                    <li>LightRoom</li>
                  </div>
                </div>
                </div>
              </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Card>
    </section>
   );
}
 
export default About;