import React from 'react';

const Footer = () => {
  return ( 
    <footer id="footer">
      <div className="footer-container">
        <div className="social-list">
          <div>
            <a href="https://www.linkedin.com/in/withman-simprevil-15a74615b/" target="blank">
              <i className="fa fa-linkedin icon"></i>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/_wsphotography/" target="blank">
              <i className="fa fa-instagram icon"></i>
            </a>
          </div>
          <div>
            <a href="https://github.com/iWithman" target="blank">
              <i className="fa fa-github-square icon"></i>
            </a>
          </div>
          
        </div>
        <div id="footer-me">
          <p>Withman Simprevil |<span> &copy;  2020</span></p>
        </div>
      </div>
    </footer>
   );
}
 
export default Footer;