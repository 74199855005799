import React from 'react';
import logo from '../images/iWithman.svg';
import '../App.scss';

const resume ='https://drive.google.com/file/d/1itQpSGDmIavTvsUwcAfIPtwCkoPpijk-/view?usp=sharing'
const NavBar = () => {
  return ( 
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="#home">
          <img alt="logo" src={logo} />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          {/* ml-auto causes it to move to the right side */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#home">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="#about">About</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link disabled" href="#projects">Projects</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link " href="#contact">Contact</a>
            </li>
            <li className="nav-item">
              <a className="nav-link my-resume" href={resume} target="blank">My Resume</a>
            </li>
          </ul>
        </div>
      </nav>
   );
}
 
export default NavBar;