import React from 'react';
import '../App.scss';
import logo from '../images/iWithman.svg'

const Animation = () => {

  return ( 
    <section id="home" className="fall">

    <div className="anim">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1536" preserveAspectRatio="xMidYMax slice">
		    <g fill="#black" fillOpacity=".15"  transform="translate(55 42)">
			   <g id="anim-bottom-layer">
				  <ellipse cx="6" cy="1009.5" rx="6" ry="5.5"/>
				  <ellipse cx="138" cy="1110.5" rx="6" ry="5.5"/>
				  <ellipse cx="298" cy="1055.5" rx="6" ry="5.5"/>
				  <ellipse cx="519" cy="1284.5" rx="6" ry="5.5"/>
				  <ellipse cx="960" cy="1155.5" rx="6" ry="5.5"/>
				  <ellipse cx="835" cy="1459.5" rx="6" ry="5.5"/>
				  <ellipse cx="178" cy="1335.5" rx="6" ry="5.5"/>
				  <ellipse cx="622" cy="1414.5" rx="6" ry="5.5"/>
				  <ellipse cx="547" cy="1234.5" rx="6" ry="5.5"/>
				  <ellipse cx="154" cy="1425.5" rx="6" ry="5.5"/>
				  <ellipse cx="731" cy="773.5" rx="6" ry="5.5"/>
				  <ellipse cx="599" cy="874.5" rx="6" ry="5.5"/>
				  <ellipse cx="339" cy="819.5" rx="6" ry="5.5"/>
				  <ellipse cx="239" cy="1004.5" rx="6" ry="5.5"/>
				  <ellipse cx="113" cy="863.5" rx="6" ry="5.5"/>
				  <ellipse cx="102" cy="1223.5" rx="6" ry="5.5"/>
				  <ellipse cx="395" cy="1155.5" rx="6" ry="5.5"/>
				  <ellipse cx="826" cy="943.5" rx="6" ry="5.5"/>
				  <ellipse cx="626" cy="1054.5" rx="6" ry="5.5"/>
				  <ellipse cx="887" cy="1366.5" rx="6" ry="5.5"/>
				  <ellipse cx="6" cy="241.5" rx="6" ry="5.5"/>
				  <ellipse cx="138" cy="342.5" rx="6" ry="5.5"/>
				  <ellipse cx="398" cy="287.5" rx="6" ry="5.5"/>
				  <ellipse cx="719" cy="516.5" rx="6" ry="5.5"/>
				  <ellipse cx="760" cy="387.5" rx="6" ry="5.5"/>
				  <ellipse cx="635" cy="691.5" rx="6" ry="5.5"/>
				  <ellipse cx="478" cy="567.5" rx="6" ry="5.5"/>
				  <ellipse cx="322" cy="646.5" rx="6" ry="5.5"/>
				  <ellipse cx="247" cy="466.5" rx="6" ry="5.5"/>
				  <ellipse cx="154" cy="657.5" rx="6" ry="5.5"/>
				  <ellipse cx="731" cy="5.5" rx="6" ry="5.5"/>
				  <ellipse cx="599" cy="106.5" rx="6" ry="5.5"/>
				  <ellipse cx="339" cy="51.5" rx="6" ry="5.5"/>
				  <ellipse cx="239" cy="236.5" rx="6" ry="5.5"/>
				  <ellipse cx="113" cy="95.5" rx="6" ry="5.5"/>
				  <ellipse cx="102" cy="455.5" rx="6" ry="5.5"/>
				  <ellipse cx="395" cy="387.5" rx="6" ry="5.5"/>
				  <ellipse cx="826" cy="175.5" rx="6" ry="5.5"/>
				  <ellipse cx="626" cy="286.5" rx="6" ry="5.5"/>
				  <ellipse cx="887" cy="598.5" rx="6" ry="5.5"/>
			   </g>
		    </g>
		    <g fill="black" fillOpacity=".4" transform="translate(65 63)">
			   <g id="anim-top-layer">
				  <circle cx="8" cy="776" r="5"/>
				  <circle cx="189" cy="925" r="2"/>
				  <circle cx="548" cy="844" r="9"/>
				  <circle cx="685" cy="1115" r="7"/>
				  <circle cx="858" cy="909" r="5"/>
				  <circle cx="874" cy="1438" r="3" transform="rotate(180 874 1438)"/>
				  <circle cx="657" cy="1256" r="6" transform="rotate(180 657 1256)"/>
				  <circle cx="443" cy="1372" r="8" transform="rotate(180 443 1372)"/>
				  <circle cx="339" cy="1107" r="7" transform="rotate(180 339 1107)"/>
				  <circle cx="24" cy="1305" r="6" transform="rotate(180 24 1305)"/>
				  <circle cx="8" cy="8" r="5"/>
				  <circle cx="189" cy="157" r="4"/>
				  <circle cx="548" cy="76" r="3"/>
				  <circle cx="685" cy="347" r="2"/>
				  <circle cx="858" cy="141" r="2"/>
				  <circle cx="874" cy="670" r="2" transform="rotate(180 874 670)"/>
				  <circle cx="657" cy="488" r="4" transform="rotate(180 657 488)"/>
				  <circle cx="443" cy="604" r="6" transform="rotate(180 443 604)"/>
				  <circle cx="339" cy="339" r="8" transform="rotate(180 339 339)"/>
				  <circle cx="4" cy="537" r="10" transform="rotate(180 24 537)"/>
			   </g>
		    </g>
		</svg>
		<div id="iWithman">
			<div id="greeting">
				<img alt="iW-logo" src={logo} />
				<p>Hello, I'm <span>Withman Simprevil</span>. <br />
				I'm a Software Developer. </p>
			</div>
			<div id="connect" className="col-md-12 text-center">
				<ul>
					<li>
        		<a href="https://github.com/iWithman"><i className="fa fa-github"></i></a>
					</li>
					<li>
        		<a href="https://www.linkedin.com/in/withman-simprevil-15a74615b/"><i className="fa fa-linkedin"></i></a>
					</li>
				</ul>
			</div>
			
		</div>
    </div>
		<div>
			<a href="#about">
				<i className="fa fa-angle-double-down scroll-down"></i>
			</a>
		</div>
</section>
   );
}
 
export default Animation;